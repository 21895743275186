@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

#home {
  position: absolute;
  top: 10rem; /* Your current offset */
  scroll-margin-top: 10rem; /* Match this to the offset */
}

.light-theme {
  --background-color: #f8f9fa;
  --text-color: black;
}

.dark-theme {
  --background-color: #180826;
  --text-color: white;
}
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Poppins', sans-serif;
}

p {
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 0rem !important;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: initial;
}

.image-container img {
  height: 200px; /* Set the desired uniform height */
  object-fit:fill; /* Ensures the image maintains its aspect ratio and covers the container */
  width: 100%; /* Optional: Ensure images fill the width of the container */
}

.hover-effect {
  position: relative;
  /* Ensures child elements respect the container */
  overflow: hidden;
  /* Prevent content from spilling out */
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Card shadow */
}

.hover-effect:hover{
  border: 1px solid  #a138fd;
  box-shadow: 0 8px 55px #a138fd;

  /* Card shadow on hover */
}

.hover-effect .image-container {
  position: relative;
  overflow: hidden;
  /* Ensure image stays within the container */
  height: 70%;
  /* Adjust height to match your design */
}

.hover-effect img {
  display: block;
  width: 100%;
  height: 100%;
  /* Ensure the image fills its container */
  transition: transform 0.7s ease-in-out;
  border-radius: 10px 10px 0 0;
  /* Rounded corners for top part */
}

.hover-effect:hover >img {
  transform: scale(1.1);
  box-shadow: 0 8px 55px #a138fd;

  /* Scale up image slightly */
}

.Nav-hover {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.Nav-hover:hover {
  transform: scale(1.0);
  /* background-color: #3a17598d; Change background color on hover */
  color: white;
  /* Optional: change text color if needed */
  border-bottom: 2.5px solid #4f1181;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: var(--background-color);
  /* padding: 10px; */
}

.grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  /* padding: 5px; */
  font-size: 30px;
  text-align: center;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
  height: 100px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2D0027;
  height: 150px;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #453057;
}


.image-row {
  display: flex;
  justify-content:center; /* Distributes space between the images */
  align-items: center;
  flex-wrap: wrap;
}

.image-row img {
  width: 17.5%; /* Set the desired reduced width */
  height: 100%; /* Maintain aspect ratio */
  margin: 0 0px; /* Add spacing between images if needed */
}



.theme-input {
  margin-bottom: 30px;
  background-color: transparent; /* Keep it transparent if needed */
  color: var(--input-text-color); /* Use variable for text color */
  border: 1px solid var(--background-color); /* Use variable for border color */
  font-size: 20px;
  padding: 15px;
}

/* Placeholder styles */
.theme-input::placeholder {
  color: var(--placeholder-color); /* Use variable for placeholder color */
}
.theme-checkbox {
  margin-bottom: 30px;
  font-size: 1.2rem; /* Increase font size of the label */
  text-align: start;
  line-height: 20px;
}

/* Label styles */
.theme-checkbox .form-check-label {
  color: var(--background-color); /* Use variable for text color */
}
