.dock {
    border-radius: 16px;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
}

.dock-container {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: rgba(83, 83, 83, 0.25);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: relative;
}

.dock-container ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

.dock-container li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: 0 8px;
    position: relative;
    transition: transform 0.3s;
}

.dock-container li:hover {
    transform: scale(1.4);
}

.dock-container .ico {
    width: 40px;
    height: 40px;
    object-fit: cover;
    transition: opacity 0.3s, box-shadow 0.3s;
    background: transparent; /* Ensure the background of the icon is transparent */
}

.dock-container li:hover .ico {
    opacity: 1; /* Ensure opacity is set to 1 */
    /* Add box-shadow to provide shadow effect on hover */
}

/* Unique shadow effects for different icons */
.ico-slack:hover {
    box-shadow:0px 3px 30px  rgba(224, 30, 90, 100%); /* Example shadow for Slack */
}

.ico-notion:hover {
    box-shadow: 0px 3px 30px  rgb(255, 255, 255,100%); /* Example shadow for Notion */
}

.ico-photoshop:hover {
    box-shadow: 0px 3px 30px  rgba(142, 207, 255, 100%); /* Example shadow for PhotoShop */
}

.ico-illustrator:hover {
    box-shadow: 0px 3px 30px  rgba(255, 154, 0, 100%); /* Example shadow for Illustrator */
}

.ico-sketch:hover {
    box-shadow: 0px 3px 30px  rgba(253, 173, 0, 100%); /* Example shadow for Sketch */
}

.ico-figma:hover {
    box-shadow:0px 3px 30px   rgb(255, 255, 255,100%); /* Example shadow for Figma */
}

.ico-framer:hover {
    box-shadow: 0px 3px 30px  rgba(170, 225, 242, 100%); /* Example shadow for Framer */
    
}

/* Tooltip styling */
.dock-container .name {
    position: absolute;
    top: -45px;
    background: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 0.9);
    height: 25px;
    padding: 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size:0.8rem;
    visibility: hidden;
    white-space: nowrap;
    z-index: 10;
    transition: opacity 0.3s;
}

.dock-container li:hover .name {
    visibility: visible;
    opacity: 1;
}

.dock-container .name::after {
    content: "";
    position: absolute;
    bottom: -15px;
    width: 0;
    height: 0;
    /* backdrop-filter: blur(13px); */
    /* -webkit-backdrop-filter: blur(13px); */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.5);
}

.dock-container .li-bin::before {
    content: "";
    position: absolute;
    top: 0;
    left: -12px;
    width: 1px;
    height: 100%;
    background-color: transparent;
}
