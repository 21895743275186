/* In a global CSS file */
:root {
    --background-color: white;
    --text-color: black;
  }
  
  [data-theme='dark'] {
    --background-color: black;
    --text-color: white;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  