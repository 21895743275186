.wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.photobanner {
  position: absolute;
  height: 60px; /* Reduced height */
  display: flex;
  margin-bottom: 10px;
}

.photobanner img {
  width: 150px; /* Adjusted width */
  height: 60px; /* Adjusted height */
  object-fit: contain; /* Ensure images maintain aspect ratio */
  margin: 0px 20px; /* Reduced margin */
  margin-left: 80px;
  margin-right: 80px;
  margin-bottom: 25px;
}

/* Left-moving animation */
.photobanner.left {
  animation: bannermove-left 40s linear infinite;
  left: 100%;
}

/* Right-moving animation */
.photobanner.right {
  animation: bannermove-right 40s linear infinite;
  right: 100%;
}

@keyframes bannermove-left {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

@keyframes bannermove-right {
  from {
    right: 0%;
  }
  to {
    right: -100%;
  }
}
