.custom-switch-container {
    display: inline-block;
    cursor: pointer;
  }
  
  .custom-switch-icon {
    width: 60px; /* Adjust the size to match your icon */
    height: auto; /* Keep proportions consistent */

  }
  